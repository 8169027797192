<template>
  <div id="nav">
    <top-panel />
  </div>
  <router-view />
  <warning-msg />
  <footer class="text-muted text-center">
    <p class="copyright text-muted text-center m-0">
      Copyright &copy; ПРОФКЕЙС 2024
    </p>
  </footer>
</template>

<script>
import TopPanel from "@/components/TopPanel.vue";
import { useStore } from "vuex";
import { onBeforeUpdate, onBeforeMount } from "vue";
import { version } from "../package.json";
import WarningMsg from "@/components/WarningMsg.vue";

export default {
  components: {
    TopPanel,
    WarningMsg,
  },
  setup() {
    const store = useStore();
    const inspectToken = async () => {
      let status = await store.dispatch("inspectToken");
      if (status) {
        await store.dispatch("FETCHuserProfile", { userId: null, phone: null });
      }
    };
    onBeforeMount(inspectToken);
    onBeforeUpdate(inspectToken);
    return {
      version,
    };
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
footer1 {
   bottom : 2px;  
   height : 40px;  
   margin-top : 40px;  
   text-align: center;  
   vertical-align: middle; 
   position:fixed;
   width:100%;
   }
</style>
