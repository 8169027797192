import { createStore } from 'vuex';
import { HTTP, AUTH, AUTHjwt } from '@/script/http-common'
import jwt_decode from 'jwt-decode'
/*eslint-disable no-unused-vars*/
import Vue from 'vue'

const searchConfluenceCategoryChildren = (currentCategory, category, fields) => {
  for (let index = 0; index < currentCategory.children.length; index++) {
    let element = currentCategory.children[index];
    if (element._id == category._id) {
      if(fields.label) element.label = category.label
      if(fields.edit) element.edit = category.edit
      if(fields.children) element.children = category.children
      break
    }
    searchConfluenceCategoryChildren(element, category, fields)
  }
}

export default createStore({
  state: {
    jwt: {
      access: localStorage.getItem('accessToken'),
      refresh: localStorage.getItem('refreshToken'),
    },
    userProfile: {},
    userProfiles: [],
    tasks: [],
    roles: [],
    taskCategories: [],
    confluenceCategories: [
      // {
      //   _id: "__new__0",
      //   label: "Бухучет",
      //   isArticle: false,
      //   parent: null,
      //   children: [
      //     {
      //       _id: "__1__",
      //       label: "Бюджет",
      //       children: [
      //         {
      //           _id: "__1-1__",
      //           label: "Расчет ЗП для гос учреждения",
      //           isArticle: true,
      //           children: []
      //         },
      //       ]
      //     },
      //     {
      //       _id: "__2__",
      //       label: "Коммерческая",
      //       children: [
      //         {
      //           _id: "__3__",
      //           label: "Проф",
      //           children: []
      //         },
      //         {
      //           _id: "__4__",
      //           label: "Корп",
      //           children: []
      //         },
      //       ]
      //     }
      //   ],
      // },
    ],
    confluenceCurrentCategory: { },
    confluenceEditMode: false,
    confluenceSortMode: false,
    confluenceFetchingCategories: true,
  },

  getters: {
    jwtAccessValid: state => () => {
      let expAccess = (state.jwt.access) ? jwt_decode(state.jwt.access).exp : 0
      return (expAccess - Date.now() / 1000 > 0) ? true : false
    },

    jwtAccess: state => () => {
      let expAccess = (state.jwt.access) ? jwt_decode(state.jwt.access).exp : 0
      return (expAccess - Date.now() / 1000 > 0) ? state.jwt.access : null
    },

    userProfile: state => {
      return state.userProfile
    },

    userProfiles: state => {
      return state.userProfiles
    },

    userName: state => {
      let username = false
      if (Object.keys(state.userProfile).length !== 0) {
        if (state.userProfile.company) {
          username = state.userProfile.company
        } else {
          username = (state.userProfile.fullname) ? state.userProfile.fullname : state.userProfile.email
        }
      }
      return username
    },

    tasks: state => {
      return state.tasks
    },

    roles: state => {
      return state.roles
    },

    taskCategories: state => {
      return state.taskCategories
    },

    isAdmin: state => {
      return state.userProfile.isAdmin
    },

    isModerator: state => {
      return state.userProfile.isModerator
    },

    confluenceCategories: state => {
      return state.confluenceCategories
    },


    confluenceCurrentCategory: state => {
      return state.confluenceCurrentCategory
    },

    confluenceEditMode: state => {
      return state.confluenceEditMode
    },

    confluenceSortMode: state => {
      return state.confluenceSortMode
    },

    confluenceFetchingCategories: state => {
      return state.confluenceFetchingCategories
    },



  },

  mutations: {
    updateToken: (state, newToken) => {
      localStorage.setItem('accessToken', newToken.access);
      localStorage.setItem('refreshToken', newToken.refresh);
      state.jwt = newToken;
      // console.log('token updated')
    },

    removeToken: (state) => {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      state.jwt.access = null
      state.jwt.refresh = null
    },

    SETuserProfile: (state, userProfile) => {
      state.userProfile = userProfile
    },

    SETuserProfiles: (state, userProfile) => {
      let index = state.userProfiles.findIndex(item => item._id == userProfile._id)
      if (index < 0) {
        state.userProfiles.push(userProfile)
      } else {
        state.userProfiles[index] = userProfile
      }
    },

    SETtasks: (state, tasks) => {
      state.tasks = tasks
    },

    SETroles: (state, roles) => {
      state.roles = roles
    },

    SETtaskCategories: (state, categories) => {
      state.taskCategories = categories
    },

    SETConfluenceCurrentCategory: (state, category) => {
      state.confluenceCurrentCategory = category
    },

    SETConfluenceEditMode: (state, mode) => {
      state.confluenceEditMode = mode
    },
    
    SETConfluenceSortMode: (state) => {
      state.confluenceSortMode = state.confluenceSortMode ? false : true
    },

    SETConfluenceFetchingCategories: (state, value) => {
      state.confluenceFetchingCategories = value
    },

    SETConfluenceCategory: (state, {category, fields}) => {
      for (let index = 0; index < state.confluenceCategories.length; index++) {
        let element = state.confluenceCategories[index];
        if (element._id == category._id) {
          console.log(element);
          if(fields.label) element.label = category.label
          if(fields.edit) element.edit = category.edit
          //if(fields.children) element.children = category.children
          //element.children = [ {_id: '123', label: '123'}] 
          
          // element = category
          //state.confluenceCategories = [...state.confluenceCategories.filter(item => item._id !== category._id), category]
          // console.log('category.children', category.children);
          // console.log('element.children', element.children);
          break
        }
        searchConfluenceCategoryChildren(element, category, fields)
      }
      //state.confluenceCategories = [...state.confluenceCategories]
    },

    SETConfluenceCategory2: (state, {category, fields}) => {
      //Vue.set(state.confluenceCategories, 0, category)
      console.log(category);
      //state.confluenceCategories = [category] //JSON.parse(JSON.stringify(category))
      state.confluenceCurrentCategory.children = [] 
      state.confluenceCurrentCategory.children = category.children
    },

    SETConfluenceCategories: (state, categories) => {
      //vue.set(state, 'confluenceCategories', [...categories]);
      state.confluenceCategories =  categories
    },

  },

 

  actions: {
    singup: async ({ dispatch, getters }, payload) => {
      var status, err, data
      try {
        let response = await AUTHjwt(getters.jwtAccess()).post('signup/', payload)
        // console.log('response singup', response);
        if (response.status >= 200 && response.status < 300) {
          status = { success: true, data: '' }
        } else {
          status = { success: false, data: response.data }
        }
      } catch (error) {
        // console.log('error.response', error.response)
        status = { success: false, data: error.response }
      }
      return status
    },

    changePassword: async ({ dispatch, getters }, password) => {
      var status, err, data
      try {
        let response = await AUTHjwt(getters.jwtAccess()).post('changePassword/', { password: password })
        if (response.status >= 200 && response.status < 300) {
          status = { status: true, data: '' }
        } else {
          status = { status: false, data: response.data }
        }
      } catch (error) {
        // console.log('error.response', error.response)
        status = { status: false, data: error.response }
      }
      return status
    },

    changePosition: async ({ dispatch, getters }, position) => {
      var status, err, data
      try {
        let response = await AUTHjwt(getters.jwtAccess()).post('changePosition/', { position: position })
        if (response.status >= 200 && response.status < 300) {
          status = { status: true, data: '' }
        } else {
          status = { status: false, data: response.data }
        }
      } catch (error) {
        // console.log('error.response', error.response)
        status = { status: false, data: error.response }
      }
      return status
    },

    restorePassword: async ({ dispatch, getters }, form) => {
      var status, err, data
      try {
        let response = await AUTHjwt(getters.jwtAccess()).post('restorePassword/', form)
        if (response.status >= 200 && response.status < 300) {
          status = { status: true, data: '' }
        } else {
          status = { status: false, data: response.data }
        }
      } catch (error) {
        // console.log('error.response', error.response)
        status = { status: false, data: error.response }
      }
      return status
    },

    obtainToken: async ({ commit, dispatch }, { username, password }) => {
      const payload = {
        username: username,
        password: password
      }
      var success, response
      var err = ''
      try {
        response = await AUTH.post('signin/', payload)
        if (response.status >= 200 && response.status < 300) {
          commit('updateToken', response.data)
          await dispatch('FETCHuserProfile', { userId: null, phone: null })
          success = true
        } else {
          err = response.data.message
        }
      } catch (error) {
        // console.log('error', error)
        success = false
        err = error.response.data.message
      }
      return { success: success, err: err }
    },

    refreshToken: async ({ state, commit }) => {
      const payload = {
        refresh: state.jwt.refresh
      }
      var success
      try {
        let response = await AUTH.post('refreshtoken/', payload)
        if (response.status >= 200 && response.status < 300) {
          commit('updateToken', response.data)
          success = true
        }
      } catch (error) {
        success = false
      }
      return success
    },

    inspectToken: async ({ state, dispatch, commit }) => {
      var success = true
      let expDelay = 900 // 15min
      let expAccess = (state.jwt.access) ? jwt_decode(state.jwt.access).exp : 0
      // console.log('----------------------------------------')
      if (expAccess - Date.now() / 1000 <= expDelay) {
        // console.log('Need updateToken')
        success = (state.jwt.refresh) ? await dispatch('refreshToken') : false
      }
      return success
    },

    FETCHuserProfile: async ({ getters, commit, dispatch }, { userId, phone }) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      try {
        if (userId) {
          let response = await HTTP(getters.jwtAccess()).get(`auth/profile/${userId}`)
          if (response.status >= 200 && response.status < 300) {
            commit('SETuserProfiles', response.data)
          }
        } else if (phone) {
          let response = await HTTP(getters.jwtAccess()).get(`auth/profile/?phone=${phone}`)
          if (response.status >= 200 && response.status < 300) {
            commit('SETuserProfiles', response.data)
          }
        } else {
          let response = await HTTP(getters.jwtAccess()).get(`auth/profile/`)
          commit('SETuserProfile', response.data)
          // console.log('SETuserProfiles', response.data);
        }
      } catch (error) {
        // console.log(error.response)
      }
    },

    FETCHmessages: async ({ getters, dispatch }, task) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      var data = []
      try {
        let response = await HTTP(getters.jwtAccess()).get(`message/?task=${task}`)
        if (response.status >= 200 && response.status < 300) {
          success = true
          data = response.data
        } else {
          success = false
          data = []
        }
      } catch (error) {
        // console.log(error.response)
        success = false
        data = []
      }
      return { status: success, data: data }
    },

    FETCHtasks: async ({ getters, dispatch, commit }) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      try {
        let response = await HTTP(getters.jwtAccess()).get(`task`)
        if (response.status >= 200 && response.status < 300) {
          success = true
          commit('SETtasks', response.data.data)
        }
      } catch (error) {
        // console.log(error.response)
        success = false
      }
      return { status: success }
    },

    uploadTaskFile: async ({ getters, dispatch }, { file, taskId }) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success, response, data
      try {
        // console.log('uploadTaskFile taskId', taskId);
        // console.log('uploadTaskFile file', file);
        let formData = new FormData()
        formData.append("file", file)

        response = await HTTP(getters.jwtAccess()).post(`task/${taskId}/uploadfile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        if (response.status >= 200 && response.status < 300) {
          success = true
        }
        data = response.data
      } catch (error) {
        success = false
        // console.log(error.response)
        data = error.response.data
      }
      return { status: success, data: data }
    },

    FETCHregistrationRequest: async ({ getters, dispatch, commit }) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      var data = []
      try {
        let response = await AUTHjwt(getters.jwtAccess()).get(`registrationRequest/`)
        // console.log('response', response)
        if (response.status >= 200 && response.status < 300) {
          success = true
          data = response.data
        }
      } catch (error) {
        // console.log(error.response)
        success = false
      }
      return { status: success, data: data }
    },

    FETCHuser: async ({ getters, dispatch, commit }) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      var data = []
      try {
        let response = await AUTHjwt(getters.jwtAccess()).get(`user/`)
        // console.log('response', response)
        if (response.status >= 200 && response.status < 300) {
          success = true
          data = response.data
        }
      } catch (error) {
        // console.log(error.response)
        success = false
      }
      return { status: success, data: data }
    },

    FETCHсolleagues: async ({ getters, dispatch }) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      var data = []
      try {
        let response = await AUTHjwt(getters.jwtAccess()).get(`colleagues/`)
        // console.log('response', response)
        if (response.status >= 200 && response.status < 300) {
          success = true
          data = response.data
        }
      } catch (error) {
        // console.log(error.response)
        success = false
      }
      return { status: success, data: data }
    },

    POSTuser: async ({ getters, dispatch, commit }, user) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      var data = []
      try {
        let response = await AUTHjwt(getters.jwtAccess()).post(`user/`, user)
        // console.log('response', response)
        if (response.status >= 200 && response.status < 300) {
          success = true
          data = response.data
        }
      } catch (error) {
        // console.log(error.response)
        success = false
        data = error.response.data
      }
      return { status: success, data: data }
    },

    POSTtask: async ({ getters, dispatch }, task) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      var data = []
      try {
        let response = await HTTP(getters.jwtAccess()).post(`task/`, task)
        if (response.status >= 200 && response.status < 300) {
          success = true
        }
        data = response.data
      } catch (error) {
        // console.log(error.response.data)
        success = false
        data = error.response.data
      }
      return { status: success, data: data }
    },

    CANCELtask: async ({ getters, dispatch }, taskId) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      var data = []
      try {
        let response = await HTTP(getters.jwtAccess()).post(`task/${taskId}/cancel`)
        if (response.status >= 200 && response.status < 300) {
          success = true
        }
        data = response.data
      } catch (error) {
        // console.log(error.response.data)
        success = false
        data = error.response.data
      }
      return { status: success, data: data }
    },

    POSTregistrationRequest: async ({ getters, dispatch, commit }, req) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      var data = []
      try {
        let response = await AUTHjwt(getters.jwtAccess()).post(`registrationRequest/`, req)
        // console.log('response', response)
        if (response.status >= 200 && response.status < 300) {
          success = true
          data = response.data
        }
      } catch (error) {
        // console.log(error.response)
        success = false
        data = error.response.data
      }
      return { status: success, data: data }
    },

    FETCHroles: async ({ getters, dispatch, commit }) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      try {
        let response = await AUTHjwt(getters.jwtAccess()).get(`role/`)
        // console.log('response', response)
        if (response.status >= 200 && response.status < 300) {
          success = true
          commit('SETroles', response.data)
        }
      } catch (error) {
        // console.log(error.response)
        success = false
      }
      return { status: success }
    },


    FETCHtaskCategory: async ({ getters, dispatch, commit }) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      try {
        let response = await HTTP(getters.jwtAccess()).get(`taskCategory`)
        if (response.status >= 200 && response.status < 300) {
          success = true
          commit('SETtaskCategories', response.data)
        }
      } catch (error) {
        // console.log(error.response)
        success = false
      }
      return { status: success }
    },


    FETCHclientService: async ({ getters, dispatch }, clientId) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      var data = []
      try {
        let response = await HTTP(getters.jwtAccess()).get(`clientService/client/${clientId}`)
        if (response.status >= 200 && response.status < 300) {
          success = true
          data = response.data.data
        }
      } catch (error) {
        // console.log(error.response)
        data = error.response.data
        success = false
      }
      return { status: success, data: data }
    },

    POSTconfluenceCategory: async ({ getters, dispatch }, category) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      var data = {}
      try {
        let response = await HTTP(getters.jwtAccess()).post(`postConfluenceCategory/`, category)
        if (response.status >= 200 && response.status < 300) {
          success = true
          data = response.data.data
        }
      } catch (error) {
        // console.log(error.response)
        data = error.response.data
        success = false
      }
      return { status: success, data: data }
    },

    DELETEconfluenceCategory: async ({ getters, dispatch }, category) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      var data = {}
      try {
        let response = await HTTP(getters.jwtAccess()).post(`deleteConfluenceCategory/`, category)
        if (response.status >= 200 && response.status < 300) {
          success = true
          data = response.data.data
        }
      } catch (error) {
        // console.log(error.response)
        data = error.response.data
        success = false
      }
      return { status: success, data: data }
    },

    POSTconfluenceFile: async ({ getters, dispatch }, formData) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      var data = {}
      try {
        let response = await HTTP(getters.jwtAccess()).post(`postConfluenceFile/`, formData)
        if (response.status >= 200 && response.status < 300) {
          success = true
          data = response.data.data
        }
      } catch (error) {
        // console.log(error.response)
        data = error.response.data
        success = false
      }
      return { status: success, data: data }
    },

    FETCHconfluenceCategories: async ({ getters, commit, dispatch }, withText=false) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      console.log('FETCHconfluenceCategories');
      commit("SETConfluenceFetchingCategories", true)
      var success = false
      var data = []
      try {
        let path = "getConfluenceCategories"
        if (withText) path += "/withText"
        let response = await HTTP(getters.jwtAccess()).get(path)
        if (response.status >= 200 && response.status < 300) {
          success = true
          data = response.data.data
          commit("SETConfluenceCategories", data)
          commit("SETConfluenceFetchingCategories", false)
        }
      } catch (error) {
        // console.log(error.response)
        data = error.response.data
        success = false
      }

      return {status: success, data: data}
    },

    SEARCHconfluenceArticle: async ({ getters, commit, dispatch }, articleText) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      var data = []
      try {
        let response = await HTTP(getters.jwtAccess()).get(`searchConfluenceArticle/${articleText}`)
        if (response.status >= 200 && response.status < 300) {
          success = true
          data = response.data.data
        }
      } catch (error) {
        // console.log(error.response)
        data = error.response.data
        success = false
      }

      return {status: success, data: data}
    },

    FETCHconfluenceArticle: async ({ getters, commit, dispatch }, articleId) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      var data = []
      try {
        let response = await HTTP(getters.jwtAccess()).get(`getConfluenceArticle/${articleId}`)
        if (response.status >= 200 && response.status < 300) {
          success = true
          data = response.data.data
        }
      } catch (error) {
        // console.log(error.response)
        data = error.response.data
        success = false
      }

      return {status: success, data: data}
    },

    FETCHconfluenceRequestList: async ({ getters, commit, dispatch }) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      var data = []
      try {
        let response = await HTTP(getters.jwtAccess()).get(`getConfluenceRequestList/`)
        if (response.status >= 200 && response.status < 300) {
          success = true
          data = response.data.data
        }
      } catch (error) {
        // console.log(error.response)
        data = error.response.data
        success = false
      }

      return {status: success, data: data}
    },


    POSTvisitArticle: async ({ getters, dispatch }, articleId) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      var data = {}
      try {
        let response = await HTTP(getters.jwtAccess()).post(`postVisitArticle/${articleId}`)
        if (response.status >= 200 && response.status < 300) {
          success = true
          data = response.data.data
        }
      } catch (error) {
        // console.log(error.response)
        data = error.response.data
        success = false
      }
      return { status: success, data: data }
    },

    MOVEArticle: async ({ getters, dispatch }, reqData) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      var data = {}
      try {
        let response = await HTTP(getters.jwtAccess()).post(`moveArticle`, reqData)
        if (response.status >= 200 && response.status < 300) {
          success = true
          data = response.data.data
        }
      } catch (error) {
        // console.log(error.response)
        data = error.response.data
        success = false
      }
      return { status: success, data: data }
    },

    POSTratingArticle: async ({ getters, dispatch }, ratingData) => {
      (getters.jwtAccessValid()) ? true : await dispatch('inspectToken')
      var success = false
      var data = {}
      try {
        let response = await HTTP(getters.jwtAccess()).post(`postRatingArticle/`, ratingData)
        if (response.status >= 200 && response.status < 300) {
          success = true
          data = response.data.data
        }
      } catch (error) {
        // console.log(error.response)
        data = error.response.data
        success = false
      }
      return { status: success, data: data }
    },



  }
})
