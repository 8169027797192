<template>
  <div class="categories-container">
    
    <button v-if="confluenceEditMode && isModerator" @click="addChildrenRoot()" class="btn btn-warning">Добавить раздел</button>
    <button v-if="confluenceEditMode && isModerator" @click="enableCategorySort()" class="btn btn-warning mx-2">Сортировка</button>

    <div class="list-group">
      <confluence-category-item
        v-for="(item) in confluenceCategories"
        :key="item._id"
        :category="item"
        :offset="10"
      />
    </div>
  </div>
</template>

<script>
import ConfluenceCategoryItem from "@/components/ConfluenceCategoryItem.vue";
import useConfluenceMixin from "@/components/confluenceMixin.js";
import useCommonMixin from "@/components/commonMixin.js";
import { useStore } from "vuex";

export default {
  name: "ConfluenceCategories",
  components: { ConfluenceCategoryItem },
  setup() {
    const {confluenceEditMode, confluenceCategories } = useConfluenceMixin()
    const { isModerator } = useCommonMixin();
    const store = useStore()

    const addChildrenRoot = async () => {
      let newId = Math.floor(Math.random() * 10000000);
      let newChildren = {
        _id: `__new__${newId}`,
        label: "",
        children: [],
        isArticle: false,
        edit: true,
        parent: null,
      };
      await store.dispatch("POSTconfluenceCategory", newChildren);
      await store.dispatch("FETCHconfluenceCategories", true);
    };

    // const moveCategory = (direction, index) => {
    //   if (index == 0 || index == confluenceCategories.value.length - 1) return
    //   if (direction == 'up') {
    //     let upperItem = 

    //   }
    // }

    const enableCategorySort = () => {
      store.commit('SETConfluenceSortMode')
    }

    return {
      confluenceCategories,
      confluenceEditMode, 
      addChildrenRoot,
      isModerator,
      enableCategorySort,
      
    };
  },
};
</script>

<style>
.categories-container {
  max-width: 100%;
}
</style>
