<template>
  <div>
    <div class="">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  setup() {}
}
</script>
