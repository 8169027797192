<template>
  <div
    class="container pt-5 pb-5"
    v-if="jwtAccessValid() && userProfile.checked"
  >
    <h3>Пользователи</h3>

    <div class="mb-3 row">
      <div class="col-sm-7">
        <div class="input-group">
          <label for="category" class="col-sm-3 col-form-label label-class"
            >Организация</label
          >

          <Multiselect
            v-model="currentClient"
            :options="clients"
            :loading="false"
            :internal-search="false"
            valueProp="_id"
            trackBy="fullname"
            label="fullname"
            :filterResults="true"
            :minChars="2"
            :resolveOnLoad="true"
            :searchable="true"
            noOptionsText="Не найден"
            :limit="20"
            placeholder="Организация"
          />

        </div>
      </div>
    </div>

    <ul class="nav nav-tabs pt-3" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="users-tab"
          data-bs-toggle="tab"
          data-bs-target="#users"
          type="button"
          role="tab"
          aria-controls="users"
          aria-selected="true"
          @click="showPeriod = false"
        >
          Пользователи
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="registrationRequests-tab"
          data-bs-toggle="tab"
          data-bs-target="#registrationRequests"
          type="button"
          role="tab"
          aria-controls="registrationRequests"
          aria-selected="false"
          @click="showPeriod = true"
        >
          Запросы на регистрацию
        </button>
      </li>
    </ul>

    <div class="tab-content" id="myTabContent">
      <!--Пользователи-->
      <div
        class="tab-pane fade show active"
        id="users"
        role="tabpanel"
        aria-labelledby="users-tab"
      >
        <users-card :client="currentClient" v-if="isMobile && isAdmin" />
        <users-table :client="currentClient" v-if="!isMobile && isAdmin" />
      </div>

      <!--Запросы на регистрацию-->
      <div
        class="tab-pane fade"
        id="registrationRequests"
        role="tabpanel"
        aria-labelledby="registrationRequests-tab"
      >
        <registration-request-card :client="currentClient" v-if="isMobile" />
        <registration-request-table :client="currentClient" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import UsersCard from "@/components/UsersCard.vue";
import UsersTable from "@/components/UsersTable.vue";
import useCommonMixin from "./commonMixin.js";
import RegistrationRequestCard from "@/components/RegistrationRequestCard.vue";
import RegistrationRequestTable from "@/components/RegistrationRequestTable.vue";
import Multiselect from "@vueform/multiselect";

export default {
  name: "Users",
  components: {
    UsersCard,
    UsersTable,
    RegistrationRequestCard,
    RegistrationRequestTable,
    Multiselect,
  },
  setup() {
    useCommonMixin();
    const { isAdmin, jwtAccessValid, userProfile } = useCommonMixin();

    const isMobile = computed(() => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    });

    const currentClient = ref("");

    const clients = computed(() =>{
      let result = []
      if (!userProfile.value.clients) return result
      userProfile.value.clients.forEach(element => {
        result.push(element.client)
      });
      return result
    })

    return {
      isMobile,
      isAdmin,
      jwtAccessValid,
      userProfile,
      currentClient,
      clients,
    };
  },
};
</script>

<style></style>
UsersTable
